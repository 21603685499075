<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/task.png" alt />
        <span class="center">服务包管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">工资单模板管理</span>
      </div>
      <div style="display: flex; align-items: center">
        <el-button @click="addTemplate" size="small" type="primary" icon="el-icon-plus">新建</el-button>
      </div>
    </div>
    <el-card class="box-card">
      <el-row type="flex" class="row-bg" style="margin-bottom: 20px">
        <el-col :span="8" style="text-align: left">
          <span class="seach_text">关键字：</span>
          <el-input
            placeholder="请输入模板名称"
            v-model="formInline.keywords"
            @input="keywords"
            size="mini"
            style="margin-right: 10px; width: 250px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </el-col>
      </el-row>

      <el-table :data="list" style="width: 100%" default-expand-all>
        <el-table-column label="模板编号">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="模板名称">
          <template slot-scope="scope">
            <span
              class="clickColor"
              @click="goDetail({ code: scope.row.code })"
            >{{ scope.row.title }}</span>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" width="160">
          <template slot-scope="scope">
            {{
            scope.row.createTime
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button type="text" @click="editTaskTemplate(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
//接口：
import { api } from '/src/api/base';

//查询工资包列表
export const taskTamplateList = api()('taskTamplate.list.json');

export default {
  components: {

  },
  data () {
    return {
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: '',
      },
      list: [],
      code: '',

    };
  },
  async created () {
    await this.loadData();
  },
  methods: {
    addTemplate () {
      this.$router.push({
        path: '/system/service/addTemplate',
        query: {
          type: 'add',
        },
      });
    },
    editTaskTemplate (row) {
      this.$router.push({
        path: '/system/service/addTemplate',
        query: {
          type: 'edit',
          code: row.code,
        },
      })
    },
    keywords () {
      this.pageNumber = 1;
      this.loadData();
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    async loadData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        $ignoreRepeat: true,
      };
      const res = await taskTamplateList(params)

      this.list = res.list
      this.total = res.total;
    },

  },
};
</script>
<style>
.el-table th.is-leaf:nth-last-child(2) {
  text-align: center !important;
}
.el-table_1_column_1 .arrClass .is-leaf {
  margin-left: 40px;
}
</style>
<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
